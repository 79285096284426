.navLink {
  @apply transition-all duration-150;
}

.navLink::after {
  content: "";
  @apply transition-all duration-150;
}

.active {
  opacity: 100;
}

.active::after {
  content: "";
  @apply bg-pinky;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transform: skew(-15deg);
  -webkit-box-shadow: 0px 0px 15px 1px #ed80fd;
  box-shadow: 0px 0px 8px 0.1px #ed80fd;
}


.neon {
  border: 1px solid #ed80fd;
}

.neon:hover {
  border: 2px solid #ed80fd;
  -webkit-box-shadow: 0px 0px 8px 0.1px #ed80fd;
  box-shadow: 0px 0px 8px 0.1px #ed80fd;
}