.main::after {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background: #df1ec759;
}
.main::before {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../public/bg/4.png');
}